import { gql } from "graphql-tag";

export const USER_FIELDS = gql`
	fragment UserFields on User {
		id
		uuid
		name
		path
		email
		status
		roles
		country_id
		image {
			file_path
		}
	}
`;

export const PAGINATION = gql`
	fragment Pagination on UserPaginator {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { USER_FIELDS, PAGINATION };
