import { gql } from "graphql-tag";
import { USER_FIELDS, PAGINATION } from "./Fragments";

export const GET_USERS = gql`
    query GetUsers($page: Int, $limit: Int) {
        users(limit:$limit, page:$page){
            ...Pagination
            data {
                ...UserFields
            }
        }
    }
    ${USER_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_USERS = gql`
    query SearchUsers($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $type:String , $date_from:String , $date_to:String) {
        search_users(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, type:$type , date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...UserFields
            }
        }
    }
    ${USER_FIELDS},
    ${PAGINATION},
`;

export default { GET_USERS, SEARCH_USERS };
