import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersList = _resolveComponent("UsersList")!
  const _component_AddUser = _resolveComponent("AddUser")!
  const _component_EditUser = _resolveComponent("EditUser")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_UsersList, { "widget-classes": "mb-5 mb-xl-8" })
    ]),
    _createVNode(_component_AddUser),
    _createVNode(_component_EditUser)
  ], 64))
}