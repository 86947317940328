
import { Input, Select } from '@/components/input-elements';
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject } from 'vue';
import { UserUpdateFormData } from '../../index';
import { Apollo, Notify } from '@/core/services';
import { UPDATE_USER } from '../graphql/Mutations';
import { GET_USERS } from '../graphql/Queries';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'edit-user-modal',
    components: {
        Input,
        Select,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const editUserForm = ref<null | HTMLFormElement>(null);
        const loading = ref(false);
        const countries: any = ref([]);
        const model: any = ref();
        const i18n = useI18n();

        const userUpdateData = ref<UserUpdateFormData>({
            id: 0,
            uuid: '',
            name: '',
            email: '',
            locales: '',
            password: '',
            password_confirmation: '',
            country_id: null,
            status: true
        });

        const submitUserForm = () => {
            editUserForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        id: userUpdateData.value.id,
                        name: userUpdateData.value.name,
                        email: userUpdateData.value.email,
                        password: userUpdateData.value.password,
                        country_id: userUpdateData.value.country_id,
                        status: userUpdateData.value.status
                    };

                    if (formData.password != '' || userUpdateData.value.password_confirmation != '') {
                        if (userUpdateData.value.password_confirmation == '') {
                            Notify.error(`${i18n.t('message.CONFIRM')} ${i18n.t('message.PASSWORD')} ${i18n.t('message.IS_REQUIRED')}`);
                            return;
                        }
                        if (formData.password == '') {
                            Notify.error(`${i18n.t('message.PASSWORD')} ${i18n.t('message.IS_REQUIRED')}`);
                            return;
                        }
                        if (formData.password != userUpdateData.value.password_confirmation) {
                            Notify.error(i18n.t('message.CONFIRM_PASSWORD_DOES_NOT_MATCH'));
                            return;
                        }
                    }

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_USER,
                        variables: { input: formData },
                        update: (store, { data: { update_user } }) => {
                            model.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(e => {
                        console.log(e);
                        loading.value = false;
                    });
                }
            });
        };

        const getCountries = () => {
            const data = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            countries.value = data.countries.map((single: Record<any, any>) => {
                const country = {};
                country['label'] = single.name;
                country['value'] = single.id;
                return country;
            });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            editUserForm.value?.resetFields();
        };

        emitter.on('editUserActions', (user: Record<any, any>) => {
            resetForm();
            getCountries();
            userUpdateData.value.id = user.id;
            userUpdateData.value.name = user.name;
            userUpdateData.value.email = user.email;
            userUpdateData.value.country_id = user.country_id;
            userUpdateData.value.status = user.status === '1' ? true : false;

            model.value = new Modal(document.getElementById('modal_edit_user')) as HTMLElement;
            model.value.show();
        });

        return {
            editUserForm,
            userUpdateData,
            loading,
            countries,
            submitUserForm
        };
    }
});
