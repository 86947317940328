
import { Input, Select } from '@/components/input-elements';
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject } from 'vue';
import { NewUserFormData } from '../../index';
import { Apollo, Notify } from '@/core/services';
import { CREATE_USER } from '../graphql/Mutations';
import { GET_USERS } from '../graphql/Queries';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'new-user-modal',
    components: {
        Input,
        Select,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const addUserForm = ref<null | HTMLFormElement>(null);
        const loading = ref(false);
        const countries: any = ref([]);
        const modal: any = ref();
        const i18n = useI18n();

        const userData = ref<NewUserFormData>({
            name: '',
            email: '',
            country_id: null,
            password: '',
            password_confirmation: '',
            status: false
        });

        const submitUserForm = () => {
            addUserForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        name: userData.value.name,
                        email: userData.value.email,
                        password: userData.value.password,
                        password_confirmation: userData.value.password_confirmation,
                        country_id: userData.value.country_id,
                        status: userData.value.status
                    };

                    if (formData.password != formData.password_confirmation) {
                        Notify.error(i18n.t('message.CONFIRM_PASSWORD_DOES_NOT_MATCH'));
                        return;
                    }

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: CREATE_USER,
                        variables: { input: formData },
                        update: (store, { data: { create_user } }) => {
                            const read_data = store.readQuery({
                                query: GET_USERS,
                                variables: {
                                    page: 1,
                                    limit: 10
                                }
                            }) as Record<any, any>;
                            store.writeQuery({
                                query: GET_USERS,
                                variables: {
                                    page: 1,
                                    limit: 10
                                },
                                data: {
                                    users: {
                                        ...read_data.users,
                                        data: [create_user, ...read_data.users.data]
                                    }
                                }
                            });

                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                        }
                    }).catch(e => {
                        console.log(e);
                        loading.value = false;
                    });
                }
            });
        };

        const getCountries = () => {
            const countries_data = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            countries.value = countries_data.countries.map((single: Record<any, any>) => {
                const country = {};
                country['label'] = single.name;
                country['value'] = single.id;
                return country;
            });
        };

        const resetForm = () => {
            emitter.emit('clearInput');
            addUserForm.value?.resetFields();
        };

        emitter.on('addUserAction', () => {
            resetForm();
            getCountries();
            modal.value = new Modal(document.getElementById('modal_add_user')) as HTMLElement;
            modal.value.show();
        });

        return {
            addUserForm,
            userData,
            loading,
            countries,
            submitUserForm
        };
    }
});
