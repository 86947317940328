
import {setPageHeader} from "@/core/helpers/toolbar";
import {defineComponent, onMounted} from "vue";
import UsersList from "../components/UsersList.vue";
import AddUser from "../components/AddUser.vue";
import EditUser from "../components/EditUser.vue";

export default defineComponent({
    name: "users",
    components: {
        UsersList,
        AddUser,
        EditUser
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.USERS message.LIST",
                actionButton : {
                    ability: "add_user",
                    pageAction: {
                        action: "addUserAction",
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.SYSTEM", link: "#"},
                    { name: "message.USERS"}
                ]
            });
        });
    }
});
