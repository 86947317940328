import { gql } from "graphql-tag";
import { USER_FIELDS } from "./Fragments";

export const CREATE_USER = gql`
	mutation CreateUser($input: UserInput!) {
		create_user(user: $input) {
			...UserFields
		}
	}
	${USER_FIELDS}
`;

export const UPDATE_USER = gql`
	mutation UpdateUser($input: UserInput!) {
		update_user(user: $input) {
			...UserFields
		}
	}
	${USER_FIELDS}
`;

export const DELETE_USER = gql`
	mutation DeleteUser($id: Int!) {
		delete_user(id: $id) {
			...UserFields
		}
	}
	${USER_FIELDS}
`;

export default { CREATE_USER, UPDATE_USER, DELETE_USER };
